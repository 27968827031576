app-signup {
  #background {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('http://local.vitay.io:4600/assets/images/vector.png');
  }

  #mini-logo {
    text-align: left;
    position: absolute;
    top: 8px;
    left: 16px;
  }

  #card-element {
    margin-top: 15px;
  }
}
